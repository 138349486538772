<template>
  <div>
    <div
      v-for="(item, activitiesIndex) in activities"
      :key="activitiesIndex"
      :class="`activityBox`"
    >
      <div v-for="(activity, index) in item" :key="index" :class="`activity`">
        <div style="display: flex; position: relative">
          <div>
            <span
              v-if="activity.type === 'START'"
              :class="`answer answerCorrect`"
            >
              <i class="fas fa-question"></i>
            </span>
            <template v-else-if="activity.type === 'SUBMIT'">
              <span
                v-if="isCorrectAnswer(activity.answer)"
                :class="`answer submitCorrect`"
              >
                <i class="fas fa-check"></i>
              </span>
              <span v-else :class="`answer submitError`">
                <i class="fas fa-times"></i>
              </span>
            </template>
            <div :class="`leftLine`"></div>
          </div>
          <div :class="`answerDetail`">
            <div>
              <template v-if="activity.type === 'START'">
                <div :class="`leftLineSTART`"></div>
              </template>
              <template v-else-if="activity.type === 'SUBMIT'">
                <div :class="`leftLineSUBMIT`"></div>
              </template>
              <template v-else-if="activity.type === 'ANSWER'">
                <div :class="`leftLineAnswer`"></div>
                <b :class="`answer`">
                  {{ getAnswerLetter(questionOrder, activity.answer) }}
                </b>
              </template>
              <template v-else>
                <div :class="`leftLineAnswer`"></div>
                <span :class="`answer answerEmpty`">-</span>
              </template>
            </div>
            <div>
              <template v-if="activity.type === 'ANSWER'">
                <span style="color: #ffc107">
                  <b>
                    Answer {{ getAnswerLetter(questionOrder, activity.answer) }}
                  </b>
                  <span>
                    ({{ instant.formatSecondToMMSS(activity.testTime) }})
                  </span>
                </span>
              </template>
              <template v-else-if="activity.type === 'SUBMIT'">
                <span>
                  <b v-if="activity.answer">
                    Submit Answer
                    <span
                      v-if="isCorrectAnswer(activity.answer)"
                      :class="`submitCorrectText`"
                    >
                      {{ getAnswerLetter(questionOrder, activity.answer) }}
                      [correct]
                    </span>
                    <span v-else :class="`submitErrorText`">
                      {{ getAnswerLetter(questionOrder, activity.answer) }}
                      [wrong]
                    </span>
                  </b>
                  <template v-else>
                    <b :class="`submitErrorText `">Skipped [wrong]</b>
                  </template>
                  <span>
                    ({{ instant.formatSecondToMMSS(activity.testTime) }})
                  </span>
                </span>
              </template>
              <template v-else>
                <span>
                  <b>{{ activity.content }}</b>
                  <span>
                    ({{ instant.formatSecondToMMSS(activity.testTime) }})
                  </span>
                </span>
              </template>
              <br />
              <small style="color: #909399">
                {{ getAnswerTime(activity, index, item) }}
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import $ from "jquery";

export default {
  metaInfo() {},

  components: {},

  mixins: [],

  props: ["activities", "correctAnswer", "questionOrder"],
  data() {
    return {};
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    }
  },
  watch: {},

  mounted() {
    this.$nextTick(() => {
      $(".activityBox")
        .find(".leftLineAnswer:last")
        .hide();
      $(".leftLine:last").hide();
      console.log($(".leftLineAnswer"));
      $(".activityBox").each(function() {
        console.log($(this)[0]);
        console.log($(this).find(".leftLineAnswer").length);
        if (!($(this).find(".leftLineAnswer").length > 0)) {
          $(this)
            .find(".leftLineSTART")
            .hide();
          $(this)
            .find(".leftLineSUBMIT")
            .hide();
        }
      });
    });
  },

  methods: {
    getAnswerLetter(questionOrder, index) {
      const oddLetters = ["A", "B", "C", "D", "E"];
      const evenLetters = ["F", "G", "H", "J", "K"];
      if (questionOrder % 2 === 1) {
        return index;
      } else {
        let i = oddLetters.indexOf(index);
        return i > -1 ? evenLetters[i] : "-";
      }
    },
    isCorrectAnswer(answer) {
      return answer == this.correctAnswer;
    },
    getAnswerTime(activity, index, item) {
      if (index < item.length - 1) {
        if (activity.testTime - item[index + 1].testTime < 0) {
          return this.instant.formatSecondToMMSS(0);
        } else {
          return this.instant.formatSecondToMMSS(
            activity.testTime - item[index + 1].testTime
          );
        }
      } else {
        return "";
      }
    }
  }
};
</script>

<style scoped>
.blurBox {
  position: relative;
  padding: 1rem;
}
.blurBoxCoverFirst {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  opacity: 0.93;
}
.blurBoxCover {
  position: absolute;
  top: 80px;
  left: 50%;
  right: auto;
  bottom: auto;
  margin: auto;
  margin-left: -265px;
  width: 500px;
  background-color: white;
  font-size: 1rem;
  z-index: 999;
  background-color: white;
  padding: 1rem;
}

.companyInfo {
  margin: 1rem 0;
}
.companyInfoItem {
  display: flex;
  margin-bottom: 0.5rem;
}
.companyInfoLabel {
  width: 5.1rem;
}
.companyInfoText {
  flex: 1;
  color: var(--themeColor);
  font-weight: 700;
}
.answer {
  display: inline-block;

  height: 1.5rem;
  text-align: center;
  line-height: 1.5rem;
  border-radius: 50%;

  min-width: 1.5rem;
  width: 1.5rem;
  background-color: #ffd75e;
  color: white;
  margin-right: 0.5rem;
  z-index: 999;
}
::v-deep .el-collapse-item__header {
  font-size: inherit;
}
.activityBox {
  margin-bottom: 1rem;
}
.leftLine {
  position: absolute;
  width: 2rem;
  left: 0.65rem;
  top: 1.5rem;
  height: 100%;
  border-left: 0.2rem solid #e4e7ed;
}
.leftLineSTART {
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 0.4rem;
  left: -0.5rem;
  transform: rotate(-45deg);
  border-left: 0.2rem solid #e4e7ed;
}
.leftLineAnswer {
  position: absolute;
  width: 2rem;
  left: 2.65rem;
  top: 1.5rem;
  height: 100%;
  border-left: 0.2rem solid #e4e7ed;
}
.leftLineSUBMIT {
  position: absolute;
  width: 2rem;
  top: -1rem;
  left: -0.3rem;
  transform: rotate(45deg);
  height: 2rem;
  border-left: 0.2rem solid #e4e7ed;
}
.answerDetail {
  position: relative;
  display: flex;
  padding-left: 2rem;
}
.isCorrect {
  color: var(--themeColor);
}
.isWrong {
  color: red;
}
.answerWrong {
  background-color: red;
}
.answerCorrect {
  background-color: #859afa;
}
.submitCorrect {
  background-color: #9adf9a;
}
.submitCorrectText {
  color: green;
}
.submitError {
  background-color: #ff4958;
}
.submitErrorText {
  color: red;
}
.answerEmpty {
  background-color: #e4e7ed;
  color: #e4e7ed;
}
</style>
