var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex"},[(_vm.myPassageContent)?_c('div',{staticStyle:{"width":"50%"}},[_c('h2',{staticClass:"title"},[_vm._v("Passage")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.myPassageContent)},on:{"mouseup":_vm.getSelectText}})]):_vm._e(),_c('div',{staticClass:"pl-4",style:(!_vm.passageContent
          ? 'width:100%;flex:1'
          : 'height: auto !important;flex:1')},[_c('h2',{staticClass:"title"},[_vm._v("Question")]),_c('div',{staticClass:"page-content p-0 mb-4",staticStyle:{"height":"auto !important"}},[_c('div',{staticClass:"myquestion"},[_c('p',[_c('span',{staticClass:"myquestion-no"},[_c('b',[_vm._v(_vm._s(_vm.questionOrder)+". ")])]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.content)}})]),_vm._l((_vm.options),function(option,optionIndex){return _c('div',{key:option.order,staticClass:"form-check"},[_c('div',{class:_vm.question.maskArr.indexOf(option.letter) > -1
                  ? 'form-check-hide'
                  : ''},[_c('input',{staticClass:"form-check-input",attrs:{"type":"radio","name":_vm.questionOrder,"id":optionIndex,"disabled":true},domProps:{"value":option.label,"checked":_vm.myAnswer == option.label}}),_c('label',{class:_vm.question.crossOutArr.indexOf(option.label) > -1
                    ? 'strike form-check-label elim-option'
                    : 'form-check-label elim-option',attrs:{"for":optionIndex},on:{"click":function($event){return _vm.onChangeOption(option.label)}}},[_vm._v(" "+_vm._s(_vm.getLetter(_vm.questionOrder, optionIndex))+". "),_c('span',{domProps:{"innerHTML":_vm._s(option.content)}})])])])}),_c('el-alert',{staticClass:"mt-2",attrs:{"type":_vm.isCorrect ? 'success' : 'error',"show-icon":"","closable":false}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('div',{staticClass:"mb-2"},[_c('b',[_vm._v(_vm._s(`Your Answer: ${ _vm.answer ? _vm.getAnswerLetter(_vm.questionOrder, _vm.answer) : "" }`))])]),_c('div',[_c('b',[_vm._v(_vm._s(`Correct Answer: ${_vm.getAnswerLetter( _vm.questionOrder, _vm.correctAnswer )}`))])])])])],2)]),(_vm.activities && _vm.activities.length > 0)?[_c('h2',{staticClass:"title"},[_vm._v("Answer Timeline")]),_c('TimeLine',{staticClass:"mb-4",attrs:{"activities":_vm.activities,"correctAnswer":_vm.correctAnswer,"questionOrder":_vm.questionOrder}})]:_vm._e(),_c('h2',{staticClass:"title"},[_vm._v("Explanation")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.explanation)}}),_c('AI',{staticClass:"mt-2",attrs:{"content":`Explanation`,"prompt":``}}),_vm._m(0)],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"AI-Explanation"},[_c('div',{})])
}]

export { render, staticRenderFns }